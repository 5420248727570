import React from 'react'

// Import icons
import { FaGithub } from 'react-icons/fa'

// Import Constants
import { ProjectsData } from '../../../constants'

const ProjectCards = () => {
  return (
    <>
    
    {ProjectsData.map((project) => (
    <article key={project.id} className='project__item'>
        <div className="project__item-image">
            <img src={project.image} alt={project.title} />
        </div>
        <h2>{project.title}</h2>
        <h4>{project.description}</h4>
        <div className="project__item-cta">
            {project.github &&
            <a href={project.github} className='btn' target="_blank"><FaGithub /> Github</a>
            }
            {project.demo &&
            <a href={project.demo} className='btn btn-primary' target="_blank">Live Demo</a>
            }
            {!project.github &&
            <h3>In Progress!</h3>
            }
        </div>
    </article>
    ))}
    
    </>
  )
}

export default ProjectCards