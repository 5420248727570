import React from 'react'

import { ContactOptionsData } from '../../../constants'

const ContactOptions = () => {
  return (
    <>
    {ContactOptionsData.map((option) => (
        <article className='contact__option'>
          
            {option.icon}
            <h4>{option.title}</h4>

            <a href={option.link} target="_blank">{option.value}</a>
            
        </article>
    ))}
    </>
  )
}

export default ContactOptions