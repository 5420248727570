import React from 'react'
import { BsLinkedin, BsInstagram } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href='https://linkedin.com/in/harry-partridge-9598a4149/' target="_blank"><BsLinkedin /></a>
        <a href='https://github.com/hazame123' target="_blank"><FaGithub /></a>
        <a href='https://www.instagram.com/harrytheopartridge/' target="_blank"><BsInstagram /></a>
    </div>
  )
}

export default HeaderSocials