import React, { useState } from 'react'
import { debounce } from 'lodash'

// Import Icons
import { BiHomeAlt, BiUser, BiServer, BiMessageSquareDetail } from 'react-icons/bi'
import { GiStack } from 'react-icons/gi'

// Import CSS
import './nav.css'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')

  // Auto Scrool on Active

  window.addEventListener('scroll', () => {
    updateNavActive()
  }, false)

  const updateNavActive = (() => {
    const sections = document.querySelectorAll('section')
    let current = '#'
      
    sections.forEach( section => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.clientHeight

        if(window.pageYOffset >= (sectionTop - sectionHeight / 3)) {
            current = '#' + section.getAttribute('id')
        }

    })


    setActiveNav(current)
    
  })
  
  return (
    <nav>
      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><BiHomeAlt /></a>
      <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><BiUser /></a>
      <a href='#stack' onClick={() => setActiveNav('#stack')} className={activeNav === '#stack' ? 'active' : ''}><GiStack /></a>
      <a href='#projects' onClick={() => setActiveNav('#projects')} className={activeNav === '#projects' ? 'active' : ''}><BiServer /></a>
      <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageSquareDetail /></a>
    </nav>
  )
}

export default Nav