// Import Images
import PROJ1 from '../assets/project1.jpg'
import PROJ2 from '../assets/project2.JPG'
import PROJ3 from '../assets/database.png'

import AVTR1 from '../assets/avatar1.jpg'
import AVTR2 from '../assets/avatar2.jpg'
import AVTR3 from '../assets/avatar3.jpg'
import AVTR4 from '../assets/avatar4.jpg'



// Import Icons
import { FiMail } from 'react-icons/fi'
import { BsWhatsapp ,BsLinkedin } from 'react-icons/bs'


// ========== SKILLS  ============ //

export const Skills = [
    {
        id: "1",
        title: "Web Development",
        level: "Experienced",
    },
    {
        id: "2",
        title: "Scripting & Automation",
        level: "Intermediate",
    },
    {
        id: "3",
        title: "Networking & Security",
        level: "Basic",
    },

]

// ========== FRONTEND EXPERIENCE ============ //

export const FrontendExperience = [
    {
        id: "1",
        title: "HTML",
        level: "Experienced",
    },
    {
        id: "2",
        title: "CSS",
        level: "Basic",
    },
    {
        id: "3",
        title: "Javascript",
        level: "Experienced",
    },
    {
        id: "4",
        title: "Typescript",
        level: "Experienced",
    },
    {
        id: "5",
        title: "React / Next",
        level: "Experienced",
    },
    {
        id: "6",
        title: "Tailwind CSS",
        level: "Experienced",
    },
    {
        id: "7",
        title: "Framer Motion",
        level: "Experienced",
    },
]

// ========== BACKEND EXPERIENCE ============ //

export const BackendExperience = [
    {
        id: "1",
        title: "Python",
        level: "Experienced",
    },
    {
        id: "2",
        title: "Linux",
        level: "Experienced",
    },
    {
        id: "3",
        title: "Django",
        level: "Intermediate",
    },
    {
        id: "4",
        title: "SQL",
        level: "Intermediate",
    },
    {
        id: "5",
        title: "PHP",
        level: "Basic",
    },
    {
        id: "6",
        title: "OAuth2",
        level: "Basic",
    },
    {
        id: "7",
        title: "AWS Cognito",
        level: "Basic",
    },
    {
        id: "8",
        title: "C++",
        level: "Basic",
    },
    {
        id: "9",
        title: "Google Cloud (Firebase)",
        level: "Intermediate",
    },
]

// ========== SERVICES DATA ============ //

export const ServicesData = [

    {
        id: "1",
        title: "Web Development",
        list: [
            "Domain name Included.",
            "24/7 Hosting - 99% Uptime",
            "tsetestsetsetetrsetestest",
            "test",
            "test2test",

        ],
    },

    {
        id: "2",
        title: "Data Management",
        list: [
            "Domain name Included.",
            "24/7 Hosting - 99% Uptime",
            "tsetestsetsetetrsetestest",
            "test",
            "test2test",
            "magic line",

        ],
    },

    {
        id: "3",
        title: "Networking",
        list: [
            "Domain name Included.",
            "24/7 Hosting - 99% Uptime",
            "tsetestsetsetetrsetestest",
            "test",
            "test2test",

        ],
    },

]

// ========== PROJECT DATA ============ //

export const ProjectsData = [
    {
        id: "1",
        image: PROJ1,
        title: "PassKeep - Python Password Manager",
        description: "A terminal based password manager using cryptography. Includes auto copy to clipboard and search functions. There is also a GUI version made with Tkinter.",
        github: "https://github.com/hazame123/passwordManager",
        demo: "",
    },
    {
        id: "2",
        image: PROJ2,
        title: "PHP Client Database with Authentication System",
        description: "A PHP client database (designed for a climbing gym). A PHP authentication system with encrypted passwords and a MySQL database to store client membership info on the backend.",
        github: "https://github.com/hazame123/phpUserDatabase",
        demo: "",
    },
    {
        id: "3",
        image: PROJ3,
        title: "Next.js + Django CMS",
        description: "Using Django and postman I created an API and then Next.js/React to build a user friendly interface to inquery the API and display data to the user. Authentication using access/refresh tokens.",
        github: "",
        demo: "",
    },

]


// ========== TESTIMONIALS DATA ============ //
export const TestimonialsData = [
    {
        id: "1",
        image: AVTR1,
        name: "Flora Kinch-Waugh",
        review: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur nesciunt doloremque vel numquam officia consequuntur obcaecati quisquam adipisci fugiat voluptatum.",
    },
    {
        id: "2",
        image: AVTR2,
        name: "Sam Jones",
        review: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur nesciunt doloremque vel numquam officia consequuntur obcaecati quisquam adipisci fugiat voluptatum.",
    },
    {
        id: "3",
        image: AVTR3,
        name: "Jamie G",
        review: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur nesciunt doloremque vel numquam officia consequuntur obcaecati quisquam adipisci fugiat voluptatum.",
    },
    {
        id: "4",
        image: AVTR4,
        name: "Julie Black",
        review: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Pariatur nesciunt doloremque vel numquam officia consequuntur obcaecati quisquam adipisci fugiat voluptatum.",
    },
]


// ========== CONTACT OPTIONS ============ //
export const ContactOptionsData = [
    {
        id: "1",
        icon: <FiMail className='contact__option-icon' />,
        title: "Email",
        value: "harrytheopartridge@gmail.com",
        link: "mailto:harrytheopartridge@gmail.com",
    },
    {
        id: "2",
        icon: <BsLinkedin className='contact__option-icon' />,
        title: "LinkedIn",
        value: "Harry Partridge",
        link: "https://www.linkedin.com/in/mrpartridge/",
    },
    {
        id: "3",
        icon: <BsWhatsapp className='contact__option-icon' />,
        title: "WhatsApp",
        value: "+44 7976 229370",
        link: "https://api.whatsapp.com/send?phone=+447976229370",
    },
]