import React from 'react'

// Import Elements
import { FrontExp, BackExp } from '../elements/exp'

// Import CSS
import './stack.css'
const Stack = () => {
  return (
    <section id='stack'>

      <h5>My Experience</h5>
      <h2>Skill Stack</h2>

      <div className="container experience__container">

        <div className="experience__fontend">
          <h3>Frontend Development</h3>
            <FrontExp />
        </div>

        <div className="experience__backend">
          <h3>Backend Development</h3>
            <BackExp />
        </div>

      </div>

    </section>
  )
}

export default Stack