import React from 'react'
import CTA from './CTA'
import CODE from '../../assets/code.jpg'
import ME from '../../assets/me.JPG'
import HeaderSocials from './HeaderSocials'

// Import CSS
import './header.css'

const Header = () => {
  return (
    <header>

        <div className="container header__container">
          <h5>Hello I'm</h5>
          <h1>Harry Partridge</h1>
          <h5 className="text-light">Software Developer - <a href="https://www.hyperiondev.com/portfolio/125238/" target="_blank">HyperionDev</a> Software Engeneering Graduate</h5>

          <CTA />

          <HeaderSocials />

          <div className="me">
            <img src={ME} alt="me" />
          </div>

          <a href='#about' className='scroll__down'>Scroll Down</a>

        </div>


    </header>
  )
}

export default Header