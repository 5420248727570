import React from 'react'

// Import Assets
import { BsPatchCheckFill } from 'react-icons/bs'

// Import Constants
import { FrontendExperience } from '../../../constants'

const FrontExp = () => {
  return (
    <div>
      <div className="experience__content">
        {FrontendExperience.map((skill) => (
          <article key={skill.id} className='experience__details'>
            <BsPatchCheckFill className='experience__details-icon' />
            <div>
              <h4>{skill.title}</h4>
              <small className='text-light'>{skill.level}</small>
            </div>
          </article>
        ))}
      </div>
    </div>
  )
}

export default FrontExp