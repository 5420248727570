import React from 'react'
import { Header, Nav, About, Stack, Services, Projects, Testimonials, Contact, Footer } from './components'

const App = () => {

  return (
    <>
        <Header />
        <Nav />
        <About />
        <Stack />
        <Projects />
        <Contact />
        <Footer />
    </>
  )
}

export default App