import React from 'react'

// Import Elements
import ProjectCards from '../elements/Projects/Projects'

// Import CSS
import './projects.css'

const Projects = () => {
  return (
    <section id='projects'>

      <h5>My Work</h5>
      <h2>Projects</h2>

      <div className="container project__container">
        <ProjectCards />
      </div>

    </section>
  )
}

export default Projects