import React from 'react'

// Import Constants
import { TestimonialsData } from '../../constants'

// Import CSS
import './testimonials.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import "swiper/css/pagination"
// import required modules
import { Pagination, Navigation } from "swiper";


const Testimonials = () => {
  return (
    <section id='testimonials'>

      <h5>Reviews from Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // Install Swiper modules
      modules={[Pagination, Navigation]}
      spaceBetween={40}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true,
                    dynamicBullets: true, }}
      >

        {TestimonialsData.map((testimonial) =>(

        <SwiperSlide key={testimonial.id} className="testimonial">

            <div className="client__avatar">
                <img src={testimonial.image} alt={testimonial.name} />
            </div>

            <h5 className="client__name">{testimonial.name}</h5>
            <small className="client__review">{testimonial.review}</small>

        </SwiperSlide>
        ))}

      </Swiper>

    </section>
  )
}

export default Testimonials