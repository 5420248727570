import React, { useRef } from 'react'
import emailjs from 'emailjs-com'

// Import Elements
import ContactOptions from '../elements/ContactOptions/ContactOptions'

// Import CSS
import './contact.css'

const Contact = () => {

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0jdy8wk', 'template_ouud7ff', form.current, '1JMDGa3EzV5cwN3cx')
      .then((result) => {
          console.log(result.text);
          e.target.reset()
          document.getElementById('result__message').innerHTML = "<label id='msg_label'>Message Sent</label>"
          document.getElementById('msg_label').className = "success"
        }, (error) => {
          document.getElementById('result__message').innerHTML = "<label id='msg_label'>" + error.text + "</label>"
          document.getElementById('msg_label').className = "error"
          console.log(error.text);
      });
  };

  return (
    <section id='contact'>

      <h5>Get in Touch</h5>
      <h2>Contact</h2>

      <div className="container contact__container">

        <div className="contact__options">
          <ContactOptions />
        </div>
        {/* End of Contact Options */}

        <form ref={form} onSubmit={sendEmail}>
          <input type="name" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        <div className='result__message' id='result__message'></div>
        </form>
      </div>

    </section>
  )
}

export default Contact