import React from 'react'
import CV from '../../assets/harrypartridge_cv.pdf'
import Certificate from '../../assets/hyperion-dev-certificate.pdf'

const CTA = () => {
  return (
    <div className='cta'>
        <a href={CV} target="_blank" className='btn'>View CV</a>
        <a href={Certificate} target="_blank" className='btn'>Bootcamp Certificate</a>
        {/* <a href='#contact' className='btn btn-primary'>Let's Talk</a> */}
    </div>
  )
}

export default CTA