import React from 'react'

// Import Constants
import { InstagramLink, LinkedinLink, GithubLink } from '../../constants'

// Import CSS
import './footer.css'

// Import Icons
import { BsLinkedin, BsInstagram } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>

      <a href="#" className='footer__logo'>Harry Partridge</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#stack">Stack</a></li>
        {/* <li><a href="#services">Services</a></li> */}
        <li><a href="#projects">Projects</a></li>
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://linkedin.com/in/harry-partridge-9598a4149/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/hazame123" target="_blank"><FaGithub /></a>
        <a href="https://www.instagram.com/harrytheopartridge/" target="_blank"><BsInstagram /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Harry Partridge. All rights reserved.</small>
      </div>

    </footer>
  )
}

export default Footer