import React from 'react'

// Import Assets
import { BsFillNodePlusFill } from 'react-icons/bs'

// Import constants
import { Skills } from '../../../constants'

const SkillCards = () => {
  return (
    <div className="about__content">
        <div className="about__cards">
          {Skills.map((skill) => (
            <article key={skill.id} className='about__card'>
                <BsFillNodePlusFill className='about__icon' />
                <h5>{skill.title}</h5>
                <small>{skill.level}</small>
            </article>
          ))}

        </div>
    </div>
  )
}

export default SkillCards