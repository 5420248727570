import React from 'react'

// Import Assets
// import KEYBOARD from '../../assets/keyboard.jpg'

// Import Elements
import SkillCards from '../elements/skillcards/SkillCards'

// Import CSS
import './about.css'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">

        {/* <div className="about__me">
          <div className="about__me-image">
            <img src={KEYBOARD} alt="About Me" />
          </div>
        </div> */}

        <div className='right-div'>

          <SkillCards />

          <p>I'm an enthusiastic self-taught software developer based in 
            London. I have been working on personal projects/client products 
            since the age of 15. Ranging from working with MySQL 
            databases to cryptography-orientated Python scripts. <br /><br />
            While diving deep into Stack Overflow rabbit holes and 
            spending hours working on the smallest of tasks, I discovered 
            that tech is all about learning. I'm keen to start my career in
            tech and even more keen to continue learning!</p>

          <a href='#contact' className='btn btn-primary'>Get in Touch</a>

        </div>

      </div>
    </section>
  )
}

export default About